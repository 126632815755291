<template>
  <b-modal
    id="modal-tipo-entrada"
    ref="my-modal"
    :title="titulo"
    no-close-on-backdrop
    ok-title="Guardar"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    @cancel="cerrarModal"
    @close="cerrarModal"
    @ok.prevent="validar"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- nombre -->
          <b-col cols="12">
            <b-form-group label-for="Nombre">
              <label for="Nombre">Nombre <span class="text-danger">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required|min:4"
              >
                <b-form-input
                  id="nombre"
                  v-model="departamento.nombre"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nombre Departamento"
                />
                <!-- :state="(errors.length > 0 || errores.nombre) ? false:null" -->
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group> </b-col
          ><b-col cols="12">
            <b-form-group label="Url" label-for="url">
              <b-input-group>
                <b-form-input id="url" v-model="departamento.url" />
                <b-input-group-append>
                  <b-button
                    v-b-modal.modal-seleccionar-enlace
                    variant="outline-primary"
                    size="sm"
                  >
                    <feather-icon icon="LinkIcon" class="mr-50" />
                    <span class="align-left">Enlaces</span>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Dependencia" label-for="Estado">
              <v-select
                id="blog-edit-estado"
                v-model="dependencia"
                label="title"
                :disabled="!$hascan('entrada.publicar')"
                :options="dependenciaOpciones"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Nivel" label-for="Estado">
              <v-select
                id="blog-edit-estado"
                v-model="nivel"
                label="title"
                :disabled="!$hascan('entrada.publicar')"
                :options="nivelOpciones"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- Estado -->
        </b-row>
      </b-form>
    </validation-observer>
     <!--Componente Enlace -->
  <EnlacesComponent @enviarUrl="recibirUrl"></EnlacesComponent>
  </b-modal>
 
</template>
<script>
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BImg,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

import vSelect from "vue-select";
import EnlacesComponent from "@/componentes/EnlacesComponent.vue";


export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BForm,
    BRow,
    BCol,
    BImg,
    BFormSelect,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    EnlacesComponent,
  },
  props: ["accion", "item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    accion: function () {
      this.errores = [];
      if (this.accion) {
        this.abrir(this.accion, this.item);
      }
    },
    nombre: function () {
      this.errores.nombre = [];
    },
    descripcion: function () {
      this.errores.descripcion = [];
    },
  },
  data() {
    return {
      id: null,
      dependencia: { id: 1, title: "Lineal" },
      dependenciaOpciones: [
        { id: 1, title: "Lineal" },
        { id: 2, title: "Funcional" },
      ],
      nivel: { id: 1, title: "Concentrado" },
      nivelOpciones: [
        { id: 1, title: "Concentrado" },
        { id: 2, title: "Desconcentrado" },
      ],

      departamento: {
        nombre: "",
        url: "",
      },

      estado: 1,
      titulo: "",
      required,
      submitted: false,
      errores: [],
    };
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.titulo == "Nuevo Departamento") {
              this.registrar();
            } else {
              this.actualizar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      switch (accion) {
        case "registrar": {
          this.titulo = "Nuevo Departamento";
          this.departamento.nombre = "";
          this.departamento.url = "";

          break;
        }
        case "actualizar": {
          this.titulo = "Editar Departamento";
          this.departamento = data;
          // this.imagen = data.imagen;
          const valor = this.nivelOpciones.find(
            (nivel) => nivel.id === data.nivel
          );
          this.nivel = { id: valor.id, title: valor.title };
          const valor2 = this.dependenciaOpciones.find(
            (dependencia) => dependencia.id === data.dependencia
          );
          this.dependencia = { id: valor2.id, title: valor2.title };
          this.id = data["id"];
          // this.nombre = data["nombre"];
          // this.estado = data["estado"];
          break;
        }
      }
    },
    cerrarModal() {
      this.$emit("cerrarComponente");
    },
    actualizarLista(item) {
      this.$emit("actualizarLista", item);
      this.submitted = false;
    },
    registrar() {
      this.departamento.nivel = this.nivel.id;
      this.departamento.dependencia = this.dependencia.id;

      axios
        .post("/departamentos", this.departamento)
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Se inserto correctamente",
            variant: "success",
            solid: false,
          });
          this.$nextTick(() => {
            this.$refs["my-modal"].toggle("#toggle-btn");
          });
          this.actualizarLista(res.data);
          this.errores = [];
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
        recibirUrl(enlace) {
      
      var itemEnlace=enlace;
      
       this.departamento.url=itemEnlace.url;
    },
    actualizar() {
      this.departamento.nivel = this.nivel.id;
      this.departamento.dependencia = this.dependencia.id;

      axios
        .put("/departamentos/" + this.id, this.departamento)
        .then((res) => {
          this.$nextTick(() => {
            this.$refs["my-modal"].toggle("#toggle-btn");
          });
          this.$bvToast.toast("Exito", {
            title: "Se Actualizo correctamente",
            variant: "success",
            solid: false,
          });
          this.actualizarLista();
          this.errores = [];
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>


